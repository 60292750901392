document.addEventListener('DOMContentLoaded', () => {
  const line_items = document.querySelectorAll('.line_item')
  const quantity_fields = document.querySelectorAll('.line-item-quantity')
  const price_fields = document.querySelectorAll('.line-item-unit-price')

  quantity_fields.forEach(item => item.addEventListener('blur', calculateLineItemTotal))
  price_fields.forEach(item => item.addEventListener('blur', calculateLineItemTotal))

  function calculateLineItemTotal(e) {
    e.preventDefault()
    const line_item = this.closest('.line-item')
    runCalculations(line_item)
  }

  function runCalculations(line_item) {
    const quantity = line_item.querySelector('input.line-item-quantity').value
    const price = line_item.querySelector('input.line-item-unit-price').value
    const total_field = line_item.querySelector('input.line-item-total')

    total = quantity * price
    total_field.value = numberToCurrency(total)
  }

  function doInitalCalculation(quantity_fields) {
    quantity_fields.forEach(field => {
      runCalculations(field.closest('.line-item'))
    })
  }

  function numberToCurrency(number) {
    return euros = new Intl.NumberFormat(`de-DE`, {
      currency: `EUR`,
      style: 'currency',
    }).format(number);
  }

  doInitalCalculation(quantity_fields)
});
