document.addEventListener('DOMContentLoaded', () => {
  const file_fields = document.querySelectorAll('input[type=file]') || []

  file_fields.forEach(field => field.addEventListener('change', setFilename))

  function setFilename(event) {
    event.preventDefault()

    if (this.files.length > 0) {
      const filename = this.parentNode.querySelector('.file-name');
      filename.textContent = this.files[0].name;
    }
  }

})
