document.addEventListener('DOMContentLoaded', () => {
  const rows = document.querySelectorAll('.is-clickable')

  rows.forEach(row => row.addEventListener('click', handleTableRowClick))

  function handleTableRowClick() {
    const href = this.dataset['href']
    window.location.href = href
  }
});
