document.addEventListener('DOMContentLoaded', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    const $notification = $delete.parentNode;

    $delete.addEventListener('click', removeNotification);
    setInterval(removeNotification, 5000);

    function removeNotification() {
      $notification.parentNode.remove();
    }
  });
});
