document.addEventListener('DOMContentLoaded', function() {
  const show_form_buttons = document.querySelectorAll('[data-showform]')
  const close_form_buttons = document.querySelectorAll('.close-form')

  show_form_buttons.forEach(form_button => form_button.addEventListener('click', showForm))
  close_form_buttons.forEach(form_button => form_button.addEventListener('click', hideForms))

  function showForm(event) {
    event.preventDefault()

    const form_name = this.dataset['showform']
    const form = document.querySelector('#'+form_name)

    hideForms(event)
    form.toggleAttribute('hidden')
  }

  function hideForms(event) {
    event.preventDefault()

    const forms = document.querySelectorAll('.hide-when-other-is-opened')
    forms.forEach(form => form.setAttribute('hidden', true))
  }
});
