document.addEventListener('DOMContentLoaded', () => {
  const targets = document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []

  targets.forEach(target => target.addEventListener('click', closeModal))

  function closeModal(event) {
    event.preventDefault()

    element = this.closest('.modal')
    element.classList.remove('is-active')
  }
})
