document.addEventListener('DOMContentLoaded', function() {
  const dynamic_lists = document.querySelectorAll('.dynamic-list')

  dynamic_lists.forEach(list => list.addEventListener('click', event => {
    event.preventDefault()

    const add_dynamic_fields_link = event.target.closest('.add-dynamic-fields-link')
    if (add_dynamic_fields_link) {
      addDynamicItem(add_dynamic_fields_link);
    }

    const remove_dynamic_fields_link = event.target.closest('.remove-dynamic-fields-link')
    if (remove_dynamic_fields_link) {
      removeDynamicItem(remove_dynamic_fields_link);
    }
  }))


  function addDynamicItem(element) {
    const time = new Date().getTime()
    const regexp = new RegExp(element.dataset['id'], 'g')
    const html = element.dataset['fields'].replace(regexp, time)

    element.insertAdjacentHTML('beforebegin', html)
  }


  function removeDynamicItem(element) {
    element.closest('.dynamic-fields').querySelector('input[type=hidden]').value = 1
    element.closest('.dynamic-fields').querySelector('.line-item').remove()
  }

})
