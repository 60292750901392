document.addEventListener('DOMContentLoaded', () => {
  const list_items = document.querySelectorAll('.tabs li')
  const links = document.querySelectorAll('.tabs-link')
  const containers = document.querySelectorAll('.tabs-container')

  const active_list_item = document.querySelector('.tabs li.is-active')
  let active_list_item_link = null

  if (active_list_item) {
    active_list_item_link = active_list_item.querySelector('.tabs-link')
  }

  links.forEach(link => link.addEventListener('click', handleTabsLinkClick))

  if (active_list_item) {
    active_list_item_link.click()
  }

  function handleTabsLinkClick(e) {
    e.preventDefault()

    // Remove active status from any line item
    // and highlight current selected line item
    list_items.forEach(item => item.classList.remove('is-active'))
    this.parentElement.classList.add('is-active')

    // Hide every tab container
    containers.forEach(item => item.classList.add('is-hidden'))

    // Extract href anchor and
    // show current selected container
    anchor = getAnchor(this.href)
    document.getElementById(anchor).classList.remove('is-hidden')
  }

  function getAnchor(url) {
    return (url.split('#').length > 1) ? url.split('#')[1] : null;
  }

});
